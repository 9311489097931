export const SMEReviewActivityFields = {
  AnalystSection: [
    {
      id: 'errorNumber',
      label: 'Related To',
      column: '',
    },
    {
      id: 'HOLD_REASON__C',
      label: 'Hold Reason',
      column: 'HOLD_REASON__C',
    },
    {
      id: 'HOLD_COMMENTS__C',
      label: 'Hold Comments',
      column: 'HOLD_COMMENTS__C',
    },
    {
      id: 'SME_REVIEW_NEEDED__C',
      label: 'SME Review Needed',
      column: 'SME_REVIEW_NEEDED__C',
    },
    {
      id: 'SME_MANAGEMENT_REVIEW_REASON__C',
      label: 'SME/Management Review Reason',
      column: 'SME_MANAGEMENT_REVIEW_REASON__C',
    },
    {
      id: 'SME_MANAGEMENT_REVIEW_COMMENTS__C',
      label: 'SME/Management Review Comments',
      column: 'SME_MANAGEMENT_REVIEW_COMMENTS__C',
    },
    {
      id: 'OF_PROVIDER_CONTRACT_DETAIL_ID_S_ON_H__C',
      label: '# of Provider Contract Detail ID(s) on H',
      column: 'OF_PROVIDER_CONTRACT_DETAIL_ID_S_ON_H__C',
    },
    {
      id: 'SME_MANAGEMENT_REVIEW_REASON__C',
      label: 'SME/Management Review Reason',
      column: 'SME_MANAGEMENT_REVIEW_REASON__C',
    },
    {
      id: 'NUMBER_OF_PROVIDER_AFFILIATIONS_ON_HOLD__C',
      label: '# of Provider Affiliations on Hold',
      column: 'NUMBER_OF_PROVIDER_AFFILIATIONS_ON_HOLD__C',
    },
    {
      id: 'REVIEW_DUE_DATE__C',
      label: 'Due Date',
      column: 'REVIEW_DUE_DATE__C',
    },
    {
      id: 'STATUS',
      label: 'Status',
      column: 'STATUS',
    },
    {
      id: 'STATUS',
      label: 'Hold Release Date',
      column: 'STATUS',
    },
    {
      id: 'PRIORITY',
      label: 'Priority',
      column: 'PRIORITY',
    },
  ],
  ReviewerSection: [
    {
      id: 'SMEMANAGEMENTREVIEWERNAME',
      label: 'SME/Management Reviewer',
      column: 'SMEMANAGEMENTREVIEWERNAME',
    },
    {
      id: 'SME_MANAGEMENT_REVIEW_RESPONSE__C',
      label: 'SME/Management Review Response',
      column: 'SME_MANAGEMENT_REVIEW_RESPONSE__C',
    },
    {
      id: 'SME_MANAGEMENT_REVIEW_DATE__C',
      label: 'Assigned To',
      column: 'SME_MANAGEMENT_REVIEW_DATE__C',
    },
  ],
};
