import React, { useState } from 'react';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Layout } from '@abyss/web/ui/Layout';

export const DateSearch = () => {
  // const [dateCreatedStart, setDateCreatedStart] = useState('');
  // const [dateCreatedEnd, setDateCreatedEnd] = useState('');

  // const [originalDateTimeReceivedStart, setOriginalDateTimeReceivedStart] =
  //   useState('');
  // const [originalDateTimeReceivedEnd, setOriginalDateTimeReceivedEnd] =
  //   useState('');

  // const [dateTimeReceivedinCirrusStart, setDateTimeReceivedinCirrusStart] =
  //   useState('');
  // const [dateTimeReceivedinCirrusEnd, setdateTimeReceivedinCirrusEnd] =
  //   useState('');

  // const [dateCompletedStart, setDateCompletedStart] = useState('');
  // const [dateCompletedEnd, setDateCompletedEnd] = useState('');

  // const [dateResolvedStart, setDateResolvedStart] = useState('');
  // const [dateResolvedEnd, setDateResolvedEnd] = useState('');

  // const [dateOfServiceStart, setDateOfServiceStart] = useState('');
  // const [dateOfServiceEnd, setDateOfServiceEnd] = useState('');

  // const [jobRunDateStart, setJobRunDateStart] = useState('');
  // const [jobRunDateEnd, setJobRunDateEnd] = useState('');

  return (
    <React.Fragment>
      <Layout.Group
        grow
        css={{
          'abyss-layout-group': {
            marginBottom: '24px',
          },
        }}
        data-testid="date-search-wrapper"
      >
        <Layout.Stack>
          <DateInput
            model="dateCreatedStart"
            label="Date Created Start"
            isClearable
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <DateInput
            label="Date Created End"
            isClearable
            width="350px"
            model="dateCreatedEnd"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>

        <Layout.Stack>
          <DateInput
            model="orignalDateTimeReceivedStart"
            label="Original Date Time Received (PQ/PV Edits) Start"
            isClearable
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <DateInput
            model="orignalDateTimeReceivedEnd"
            label="Original Date Time Received (PQ/PV Edits) End"
            isClearable
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>

        <Layout.Stack>
          <DateInput
            model="dateTimeReceivedStart"
            label="Date Time received in Cirrus (PQ Edits) Start"
            isClearable
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <DateInput
            model="dateTimeReceivedEnd"
            label="Date Time received in Cirrus (PQ Edits) End"
            isClearable
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
      </Layout.Group>

      <Layout.Group
        grow
        css={{
          'abyss-layout-group': {
            marginBottom: '24px',
          },
        }}
      >
        <Layout.Stack>
          <DateInput
            model="dateCompletedStart"
            label="Date Completed Start"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <DateInput
            model="dateCompletedEnd"
            label="Date Completed End"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
        <Layout.Stack>
          <DateInput
            model="dateResolvedStart"
            label="Date Resolved (PQ Edits) Start"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <DateInput
            model="dateResolvedEnd"
            label="Date Resolved (PQ Edits) End"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
        <Layout.Stack>
          <DateInput
            model="dateOfServiceStart"
            label="Date of Service Start"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <DateInput
            model="dateOfServiceEnd"
            label="Date of Service End"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
      </Layout.Group>

      <Layout.Group grow>
        <Layout.Stack>
          <DateInput
            model="jobRunDateStart"
            label="Job Run Date Start"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <DateInput
            model="jobRunDateEnd"
            label="Job Run Date End"
            width="350px"
            css={{
              'abyss-date-picker-input-descriptors': {
                display: 'none',
              },
              'abyss-date-picker-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
      </Layout.Group>
    </React.Fragment>
  );
};
