// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { config } from '@abyss/web/tools/config';

export const searchActivity = (
  errorId,
  activityForm,
  setShowActivities,
  setTaskSubject,
  setActivities
) => {
  const apiUrl = config('API_URL');

  axios
    .post(`${apiUrl}/activityById`, {
      data: {
        input: errorId,
      },
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        setActivities(responseData);
        setTaskSubject(responseData[0]?.SUBJECT);

        // activityForm.setValue('activity-subject', responseData[0]?.SUBJECT);
        // activityForm.setValue(
        //   'activity-assignedTo',
        //   responseData[0]?.TASK_OWNER_NAME__C
        // );
        // activityForm.setValue('activity-name', responseData[0]?.NAME);
        // activityForm.setValue(
        //   'activity-comments',
        //   responseData[0]?.HOLD_COMMENTS__C
        // );
        // activityForm.setValue(
        //   'activity-dueDate',
        //   responseData[0]?.REVIEW_DUE_DATE__C
        // );
        // activityForm.setValue('activity-errorNumber', responseData[0]?.ERRORID);
        // activityForm.setValue('activity-status', responseData[0]?.STATUS);
        // activityForm.setValue('activity-priority', responseData[0]?.PRORITY);
        // activityForm.setValue(
        //   'activity-createdBy',
        //   responseData[0]?.CREATEDBYNAME
        // );

        setShowActivities(true);
      } else {
        setShowActivities(false);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
