/* eslint-disable import/no-extraneous-dependencies */
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@src/configs/authConfig';

export const useAuth = () => {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (
    msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
  }

  // Listen for Sign In and set Active Account
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const { account } = event.payload;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};
