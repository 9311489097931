export const commentsDataTable = [
  {
    Header: 'User',

    accessor: 'OWNERFULLNAME',
  },

  {
    Header: 'Created Date',
    accessor: 'CREATEDDATE',
    Cell: ({ value }) => {
      return value?.split('T')[0];
    },
  },

  {
    Header: 'Comments',

    accessor: 'COMMENTBODY',
  },
];
