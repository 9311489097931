export const auditTaskActivityFields = [
  {
    id: 'errorNumber',
    label: 'Related To',
    column: '',
  },
  {
    id: 'auditor',
    label: 'Auditor',
    column: 'AUDITOR__C',
  },
  {
    id: 'processMonth',
    label: 'Process Month',
    column: 'PROCESS_MONTH__C',
  },
  {
    id: 'qualityComments',
    label: 'Quality Comments',
    column: 'QUALITY_COMMENTS__C',
  },
  {
    id: 'correctionNeeded',
    label: 'Correction Needed',
    column: 'CORRECTION_NEEDED__C',
  },
  {
    id: 'correctionCompletedBy',
    label: 'Correction Completed By',
    column: 'CORRECTION_COMPLETED_BY__C',
  },
  {
    id: 'dateCorrectionCompleted',
    label: 'Date Correction Completed',
    column: 'DATE_CORRECTION_COMPLETED__C',
  },
  {
    id: 'correctionComments',
    label: 'Correction Comments',
    column: 'CORRECTION_COMMENTS_AUDIT_TASK__C',
  },
  {
    id: 'dateCorrectionVerifiedByAuditor',
    label: 'Date Correction Verified by Auditor',
    column: 'DATE_CORRECTION_VERIFIED_BY_AUDITOR__C',
  },
  {
    id: 'auditStatus',
    label: 'Audit Status',
    column: 'AUDIT_STATUS__C',
  },
  {
    id: 'callibrationOutcome',
    label: 'Callibration Outcome',
    column: 'CALIBRATION_OUTCOME__C',
  },
  {
    id: 'callibrationComments',
    label: 'Callibration Comments',
    column: 'CALIBRATION_COMMENTS__C',
  },
  {
    id: 'overturnedComments',
    label: 'Overturned Comments',
    column: 'OVERTURNED_COMMENTS__C',
  },
  {
    id: 'finalOutcome',
    label: 'Final Outcome',
    column: 'FINAL_OUTCOME_AUDIT_TASK__C',
  },
  {
    id: 'finalComments',
    label: 'Final Comments',
    column: 'FINAL_COMMENTS__C',
  },
  {
    id: 'overturnedReason',
    label: 'Overturned Reason',
    column: 'OVERTURNED_REASON_AUDIT_TASK__C',
  },
  {
    id: 'assignedTo',
    label: 'Assigned To',
    column: 'TASK_OWNER_NAME__C',
  },
  {
    id: 'auditErrorReasonCode',
    label: 'Audit Error Reason Code',
    column: 'ERROR_CODEAUDIT__C',
  },
  {
    id: 'impactErrorCode',
    label: 'Impact Error Code',
    column: 'IMPACT_ERROR_CODE__C',
  },
  {
    id: 'errorProductionStatus',
    label: 'Error Production Status',
    column: 'ERROR_PRODUCTION_STATUS__C',
  },
  {
    id: 'pointValue',
    label: 'Point Value',
    column: 'POINT_VALUE__C',
  },
  {
    id: 'errorSource',
    label: 'Error Source',
    column: 'ERROR_SOURCEAUDIT__C',
  },
  {
    id: 'impactErrorSource',
    label: 'Impact Error Source',
    column: 'IMPACT_ERROR_SOURCE__C',
  },
  {
    id: 'auditType',
    label: 'Audit Type',
    column: 'AUDIT_TYPE__C',
  },
  {
    id: 'subject',
    label: 'Subject',
    column: 'SUBJECT',
  },
  {
    id: 'status',
    label: 'Status',
    column: 'STATUS',
  },
  {
    id: 'priority',
    label: 'Priority',
    column: 'PRIORITY',
  },
];
