import { Link } from '@abyss/web/ui/Link';

export const errorDataTable = [
  {
    Header: 'Show Details',
    accessor: 'ID',
    canToggleVisibilty: true,
    isHiddenByDefault: false,
    Cell: ({ value }) => {
      return (
        <Link href={`/error/${value}`} openNewWindow>
          {' '}
          Show Details{' '}
        </Link>
      );
    },
  },
  {
    Header: 'Error Number',

    accessor: 'CASENUMBER',
    // Cell: ({ value }) => {
    //   return <Link href="https://www.google.com">{value}</Link>;
    // },
  },

  {
    Header: 'Audit ID',

    accessor: 'AUDIT_NAME_ID',
    Cell: ({ value }) => {
      return value && value?.length > 0 ? value : 'No Audit';
    },
  },

  {
    Header: 'Claim ID',

    accessor: 'CLAIM_ID__C',
  },

  {
    Header: 'Contract ID',

    accessor: 'CONTRACT_ID__C',
  },

  {
    Header: 'Corp Provider Identifier',

    accessor: 'CORP_PROVIDER_IDENTIFIER__C',
  },

  {
    Header: 'Correlation ID',

    accessor: 'CORRELATION_ID__C',
  },

  {
    Header: 'Date Created',

    accessor: 'DATE_THROWN__C',
  },

  // {
  //   Header: 'Date Completed',

  //   accessor: 'DATE_COMPLETED_NEW__C',
  // },

  {
    Header: 'Description',

    accessor: 'DESCRIPTION',
  },

  {
    Header: 'Error Code',

    accessor: 'ERROR_CODE__C',
  },

  {
    Header: 'Error Owner',

    accessor: 'USERFULLNAME',
  },

  {
    Header: 'External Status',

    accessor: 'EXTERNAL_STATUS__C',
  },

  {
    Header: 'HCO LTK',

    accessor: 'HCO_LTK__C',
    disableResizing: true,
    width: 170,
  },

  {
    Header: 'HCP LTK',

    accessor: 'HCP_LTK__C',
    disableResizing: true,
    width: 170,
  },

  {
    Header: 'HCPP NUM',

    accessor: 'HCPP_NUM__C',
  },

  {
    Header: 'Last Name',

    accessor: 'LAST_NAME__C',
  },

  {
    Header: 'MPIN',

    accessor: 'MPIN__C',
  },

  {
    Header: 'Network ID',

    accessor: 'NTWK_ID__C',
  },

  {
    Header: 'NICE DEC ID',

    accessor: 'NICE_DEC_ID__C',
  },

  {
    Header: 'Org Name',

    accessor: 'ORG_NAME__C',
  },

  {
    Header: 'ORG_TYPE_CD',

    accessor: 'ORG_TYPE_CD__C',
  },

  {
    Header: 'Pricing Schedule',

    accessor: 'PRICING_SCHEDULE_OSS_ID__C',
  },

  {
    Header: 'PRICING SET ID',

    accessor: 'PRICING_SET_ID__C',
  },

  {
    Header: 'Provider Contract Source ID',

    accessor: 'PROVIDER_CONTRACT_SOURCE_ID__C',
  },

  {
    Header: 'Provider Roster Type',

    accessor: 'PROVIDER_ROSTER_TYPE__C',
  },

  {
    Header: 'Reassign_HCO_SP_Relationship Key',

    accessor: 'REASSIGN_HCO_SP_RELATIONSHIP_KEY__C',
  },

  {
    Header: 'Reassign_HCP_LTK',

    accessor: 'REASSIGN_HCP_LTK__C',
  },

  {
    Header: 'SF Status',

    accessor: 'SF_STATUS__C',
  },

  {
    Header: 'SP LTK',

    accessor: 'SP_LTK__C',
  },

  {
    Header: 'Summary',

    accessor: 'SUMMARY__C',
  },

  {
    Header: 'Transaction Run Date',

    accessor: 'TRANSACTION_RUN_DATE__C',
  },
  {
    Header: 'Salesforce Queue Name',

    accessor: 'QUEUE_NAME__C',
  },
  {
    Header: 'Salesforce Status',

    accessor: 'STATUS',
  },
];
