import React, { useMemo, useState } from 'react';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Accordion } from '@abyss/web/ui/Accordion';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from '@abyss/web/ui/Tabs';
import { Header } from '@src/common/Header';
import { search, searchAudit } from '../../utils/use';
import { auditDataTable } from '../../definations/audit';
import { errorDataTable } from '../../definations/error';
import { GeneralSearch } from '../../components/GeneralSearch';
import { DateSearch } from '../../components/DateSearch';

const RootContainer = styled(Layout.Stack, {
  padding: '2rem 4rem 4rem 4rem',
  minHeight: '20vh',
  justifyContent: 'space-between',
  variants: {
    medium: {
      true: {
        padding: '2rem',
      },
    },
  },
});

export const Home = ({ title }) => {
  const isMedium = useMediaQuery('(max-width: 744px)');

  const searchFormData = useForm();
  const [showErrorDataTable, setShowErrorDataTable] = useState(false);

  const reset = () => {
    searchFormData.reset();
  };

  const [isLoading, setIsLoading] = useState(false);

  const dataTablePropsPagination = useDataTable({
    initialData: [],
    initialColumns: useMemo(() => {
      return errorDataTable;
    }, []),
    // showSelection: true,    ~~~ Not required
    showPagination: true,
    showTopPagination: true,
    showBottomPagination: true,
    // showGlobalFilter: true,
    showDownloadButton: true,
    paginationBottomCompact: true,
    pageSizeDefault: 20,
    // showFullscreenButton: true, // ~~~ Screen Going to Parent
    // highlightRowOnHover: true,
    // showFilterDataset: true, // ~~~ Code Break
    // bulkActions,
    // individualActions,
    downloadButtonConfig: {
      removeFiltered: true,
    },
    pageSizeOptions: [20, 40, 60, 80],
  });

  const dataTableAudit = useDataTable({
    initialData: [],
    initialColumns: useMemo(() => {
      return auditDataTable;
    }, []),
    showPagination: true,
    showTopPagination: true,
    showBottomPagination: true,
    showDownloadButton: true,
    paginationBottomCompact: true,
    pageSizeDefault: 20,
    pageSizeOptions: [20, 40, 60, 80],
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    search(data, dataTablePropsPagination, setShowErrorDataTable, setIsLoading);
    // searchAudit(data, dataTableAudit);
  };

  const handleFormReset = (event) => {
    event.preventDefault();
    // Custom reset behavior or no action
  };

  // const activeAccountUserName = useSelector((state) => {
  //   return state.activeAccountUserName;
  // });

  const currentDate = Date.now();

  const formatDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(currentDate);

  return (
    <div className="landing-page-wrapper" data-testid="landing-page-wrapper">
      <Header title={title} />
      <meta name="description" content={`${title}Page`} />
      <RootContainer grow medium={isMedium}>
        <Heading offset={3}>Search Records</Heading>
        <FormProvider
          state={searchFormData}
          onSubmit={onSubmit}
          onReset={handleFormReset}
        >
          <Accordion defaultValue="general-search" isCollapsible>
            <Accordion.Item value="date-search">
              <Accordion.Trigger>
                <Layout.Group>
                  <IconMaterial icon="add" />
                  <Accordion.Header>Date Search</Accordion.Header>
                </Layout.Group>
              </Accordion.Trigger>
              <Accordion.Content>
                <DateSearch />
              </Accordion.Content>
            </Accordion.Item>

            <Accordion.Item value="general-search">
              <Accordion.Trigger>
                <Layout.Group>
                  <IconMaterial icon="add" />
                  <Accordion.Header>General Search</Accordion.Header>
                </Layout.Group>
              </Accordion.Trigger>
              <Accordion.Content>
                <GeneralSearch />
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
          <Layout.Group
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type="submit"
              size="$md"
              isLoading={isLoading}
              ariaLoadingLabel="fetching data"
              style={{ marginTop: '15px' }}
            >
              Search
            </Button>
            <Button
              type="reset"
              size="$md"
              onClick={reset}
              style={{ marginTop: '15px' }}
            >
              Clear
            </Button>
          </Layout.Group>
        </FormProvider>

        {showErrorDataTable ? (
          <DataTable
            title="Error Result"
            tableState={dataTablePropsPagination}
          />
        ) : (
          <div className="content-center">
            <h3>No Results found!!</h3>
          </div>
        )}
      </RootContainer>
    </div>
  );
};
