export const auditDataTable = [
  {
    Header: 'Audit ID',

    accessor: 'ID',
  },

  {
    Header: 'Audit Error Reason Code',

    accessor: 'AUDIT_ERROR_REASON_CODE__C',
  },

  {
    Header: 'Audit Status',

    accessor: 'AUDIT_STATUS__C',
  },

  {
    Header: 'Auditor',

    accessor: 'AUDITORFULLNAME',
  },

  {
    Header: 'Date Completed',

    accessor: 'DATE_COMPLETED__C',
  },

  {
    Header: 'Final Comments',

    accessor: 'FINAL_COMMENTS__C',
  },

  {
    Header: 'Final Outcome',

    accessor: 'FINAL_OUTCOME__C',
  },

  {
    Header: 'Overturned Comments',

    accessor: 'OVERTURNED_COMMENTS__C',
  },

  {
    Header: 'Overturned Reason',

    accessor: 'OVERTURNED_REASON__C',
  },

  {
    Header: 'Owner',

    accessor: 'USERFULLNAME',
  },
  {
    Header: 'Quality Comments',

    accessor: 'QUALITY_COMMENTS__C',
  },
];
