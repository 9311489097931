export const CommonActivityFields = {
  TaskInformation: [
    {
      id: 'errorNumber',
      label: 'Related To',
      column: '',
    },
    {
      id: 'subject',
      label: 'Subject',
      column: 'SUBJECT',
    },
    {
      id: 'taskOwnerName',
      label: 'Assigned To',
      column: 'TASK_OWNER_NAME__C',
    },
    {
      id: 'name',
      label: 'Name',
      column: 'NAME',
    },
    {
      id: 'description',
      label: 'Comments',
      column: 'DESCRIPTION',
    },
    {
      id: 'reviewDueDate',
      label: 'Due Date',
      column: 'REVIEW_DUE_DATE__C',
    },
  ],
  AdditionalInformation: [
    {
      id: 'priority',
      label: 'Priority',
      column: 'PRIORITY',
    },
    {
      id: 'status',
      label: 'Status',
      column: 'STATUS',
    },
  ],
  SystemInformation: [
    {
      id: 'createdBy',
      label: 'Created By',
      column: 'CREATEDBYNAME',
    },
  ],
};
