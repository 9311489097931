import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import { AppBody } from '../common/AppBody';
import { Home } from './Home';
import { MoreDetails } from './MoreDetails';

export const Routes = () => {
  return (
    <Router.Routes title="{{title}} | Abyss">
      <Router.Route path="/" element={<AppBody />}>
        <Router.Route path="/" element={<Home title="Home" />} />
        <Router.Route
          path="/error/:errorid"
          element={<MoreDetails title="MoreDetails" />}
        />
      </Router.Route>
      {/* <Router.Route
        path="/moreDetails/:errorid"
        element={<MoreDetails title="MoreDetails" />}
      /> */}
    </Router.Routes>
  );
};

// export const Routes = () => {
//   return (
//     <Router.Routes>
//       <Router.Route path="/hello-abyss" element={<HelloAbyss />} />
//       <Router.Route path="/" element={<Home />} />
//       <Router.Route
//         path="/ndb_pln_ddp_status_tin_mpin_level"
//         element={<ReportHome />}
//       />
//       <Router.Route path="/StrategicDisruptions" element={<SDRHome />} />
//     </Router.Routes>
//   );
// };
