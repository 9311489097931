/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
// import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Router } from '@abyss/web/ui/Router';
import { useAuth } from '@src/hooks/useAuth';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { Login } from '@src/configs/Login/Login';

export const AppBody = () => {
  // const isMedium = useMediaQuery('(max-width: 744px)');

  const msalInstance = useAuth();

  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <div className="zoom">
            <Router.Outlet />
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </React.Fragment>
  );
};
