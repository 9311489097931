import React from 'react';
import { Card } from '@abyss/web/ui/Card';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Layout } from '@abyss/web/ui/Layout';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Flex } from '@abyss/web/ui/Flex';
import { SMEReviewActivityFields } from '@src/definations/SMEreviewActitvityFields';
import { ActivityField } from './ActivityField';

export const SMEReviewActivity = ({ item }) => {
  return (
    <React.Fragment>
      <Card header="SME Review" collapse defaultIsOpen={false}>
        <Card.Section>
          <Accordion defaultValue="Task">
            <Accordion.Item value="Task Information">
              <Accordion.Trigger>
                <Layout.Group>
                  <IconMaterial icon="add" />
                  <Accordion.Header>
                    SME Review - Analyst Section
                  </Accordion.Header>
                </Layout.Group>
              </Accordion.Trigger>
              <Accordion.Content>
                <Flex justify="space-between">
                  {SMEReviewActivityFields.AnalystSection.map((field) => {
                    return <ActivityField item={item} field={field} />;
                  })}
                </Flex>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="SME Reviewer Section">
              <Accordion.Trigger>
                <Layout.Group>
                  <IconMaterial icon="add" />
                  <Accordion.Header>SME Reviewer Section</Accordion.Header>
                </Layout.Group>
              </Accordion.Trigger>
              <Accordion.Content>
                <Flex>
                  {SMEReviewActivityFields.ReviewerSection.map((field) => {
                    return <ActivityField item={item} field={field} />;
                  })}
                </Flex>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </Card.Section>
      </Card>
    </React.Fragment>
  );
};
