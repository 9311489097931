/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { config } from '@abyss/web/tools/config';

const apiUrl = config('API_URL');

export const search = (
  inputPayload,
  dataTablePropsPagination,
  setShowErrorDataTable,
  setIsLoading
) => {
  axios
    .post(`${apiUrl}/fetchSearchUIErrorData`, {
      data: {
        type: 'error',
        input: inputPayload,
      },
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        // console.log('axios API===', responseData);
        // setTableData(responseData); // Using it to set filter values
        console.log('api_data', responseData);
        dataTablePropsPagination.setData(responseData, true);
        setShowErrorDataTable(true);
        setIsLoading(false);
        // setSubmitisLoading(false)
      } else {
        setShowErrorDataTable(false);
        setIsLoading(false);
      }
    })
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
    });
};

export const searchComments = (
  inputPayload,
  dataTableComments,
  setShowComments
) => {
  axios
    .post(`${apiUrl}/searchById`, {
      data: {
        type: 'comments',
        input: inputPayload,
      },
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        console.log('comments', responseData);
        dataTableComments.setData(responseData, true);
        setShowComments(true);
      } else {
        setShowComments(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const searchErrorDetails = (errorid, form) => {
  axios
    .post(`${apiUrl}/searchById`, {
      data: {
        type: 'error',
        input: errorid,
      },
    })
    .then((response) => {
      const responseData = response.data.results;
      console.log('responseFront:', responseData);
      form.setValue(
        'transactionRunDate',
        responseData[0]?.TRANSACTION_RUN_DATE__C
      );
      form.setValue('summary', responseData[0]?.SUMMARY__C);
      form.setValue('SPLTK', responseData[0]?.SP_LTK__C);
      form.setValue('SFStatus', responseData[0]?.STATUS);
      form.setValue('salesforceQueueName', responseData[0]?.QUEUE_NAME__C);
      form.setValue('reassign_HCP_LTK', responseData[0]?.REASSIGN_HCP_LTK__C);
      form.setValue(
        'reassign_HCO_SP_RelationshipKey',
        responseData[0]?.REASSIGN_HCO_SP_RELATIONSHIP_KEY__C
      );
      form.setValue(
        'providerRosterType',
        responseData[0]?.PROVIDER_ROSTER_TYPE__C
      );
      form.setValue(
        'providerContractSourceID',
        responseData[0]?.PROVIDER_CONTRACT_SOURCE_ID__C
      );
      form.setValue('PRICINGSETID', responseData[0]?.PRICING_SET_ID__C);
      form.setValue(
        'pricingSchedule',
        responseData[0]?.PRICING_SCHEDULE_OSS_ID__C
      );
      form.setValue('orgName', responseData[0]?.ORG_TYPE_CD__C);
      form.setValue('niceDecID', responseData[0]?.NICE_DEC_ID__C);
      form.setValue('networkID', responseData[0]?.NTWK_ID__C);
      form.setValue('mPIN', responseData[0]?.MPIN__C);
      form.setValue('lastName', responseData[0]?.LAST_NAME__C);
      form.setValue('hcppNum', responseData[0]?.HCPP_NUM__C);
      form.setValue('hcpLTK', responseData[0]?.HCP_LTK__C);
      form.setValue('hcoLTK', responseData[0]?.HCO_LTK__C);
      form.setValue('externalStatus', responseData[0]?.EXTERNAL_STATUS__C);
      form.setValue('errorOwner', responseData[0]?.USERFULLNAME);
      form.setValue('errorCode', responseData[0]?.ERROR_CODE__C);
      form.setValue('description', responseData[0]?.DESCRIPTION);
      form.setValue('dateCreated', responseData[0]?.DATE_CREATED__C);
      form.setValue('correlationId', responseData[0]?.CORRELATION_ID__C);
      form.setValue(
        'corpProviderIdentifier',
        responseData[0]?.CORP_PROVIDER_IDENTIFIER__C
      );
      form.setValue('contractId', responseData[0]?.CONTRACT_ID__C);
      form.setValue('claimId', responseData[0]?.CLAIM_ID__C);
      form.setValue('errorNumber', responseData[0]?.CASENUMBER);
      // form.setValue('cirrusTransactionId', responseData[0]?.CORRELATION_ID__C);

      return responseData;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const searchAuditDetails = (errorid, form, setShowAudit) => {
  axios
    .post(`${apiUrl}/fetchSearchUIErrorData`, {
      data: {
        type: 'audit',
        input: errorid,
      },
    })
    .then((response) => {
      const responseData = response.data.results;
      console.log('responseFront:', responseData);
      if (responseData && responseData?.length && responseData.length > 0) {
        form.setValue('auditId', responseData[0]?.NAME);
        form.setValue('aerc', responseData[0]?.AUDIT_ERROR_REASON_CODE__C);
        form.setValue('auditStatus', responseData[0]?.AUDIT_STATUS__C);
        form.setValue('auditor', responseData[0]?.AUDITORFULLNAME);
        form.setValue(
          'calibrationComments',
          responseData[0]?.CALIBRATION_COMMENTS__C
        );
        form.setValue(
          'calibrationOutcome',
          responseData[0]?.CALIBRATION_OUTCOME__C
        );
        form.setValue(
          'correctionComments',
          responseData[0]?.CORRECTION_COMMENTS__C
        );
        form.setValue('dateCreated', responseData[0]?.CREATEDDATE);
        form.setValue('dateCompleted', responseData[0]?.DATE_COMPLETED__C);
        form.setValue('finalComments', responseData[0]?.FINAL_COMMENTS__C);
        form.setValue('finalOutcome', responseData[0]?.FINAL_OUTCOME__C);
        form.setValue(
          'overturnedComments',
          responseData[0]?.OVERTURNED_COMMENTS__C
        );
        form.setValue(
          'overturnedReason',
          responseData[0]?.OVERTURNED_REASON__C
        );
        form.setValue('owner', responseData[0]?.USERFULLNAME);
        form.setValue('qualityComments', responseData[0]?.QUALITY_COMMENTS__C);
        setShowAudit(true);
      }
      return responseData;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const searchAttachmentDetails = (
  inputPayload,
  dataTableAttachment,
  setShowAttachment
) => {
  axios
    .post(`${apiUrl}/searchById`, {
      data: {
        type: 'attachment',
        input: inputPayload,
      },
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        console.log('Attachment', responseData);
        dataTableAttachment.setData(responseData, true);
        setShowAttachment(true);
      } else {
        setShowAttachment(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
