import React from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Layout } from '@abyss/web/ui/Layout';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';

export const GeneralSearch = () => {
  // const [value, setValue] = useState('State Default Value');
  return (
    <React.Fragment>
      <Layout.Group grow>
        <Layout.Stack>
          <TextInput
            model="auditId"
            label="Audit ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
            data-testid="auditId"
          />
          <TextInput
            model="billingProviderNPI"
            label="Billing Provider NPI"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="billingProviderTINName"
            label="Billing Provider TIN Name"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="cirrusTransactionID"
            label="Cirrus Transaction ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="claimID"
            label="Claim ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="claimType"
            label="Claim Type"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="contractId"
            label="Contract ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="contractSourceSystemID"
            label="Contract Source System ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
        <Layout.Stack>
          <TextInput
            model="cropProviderIdentifier"
            label="Corp Provider Identifier"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="correlationId"
            label="Correlation ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="errorCode"
            label="Error Code"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="errorNumber"
            label="Error Number"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="errorOwner"
            label="Error Owner"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="facilityType"
            label="Facility Type"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="firstName"
            label="First Name"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="lastName"
            label="Last Name"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
        <Layout.Stack>
          <TextInput
            model="hcoLTK"
            label="HCO LTK"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="hcpLTK"
            label="HCP LTK"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="hcppNUM"
            label="HCPP NUM"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="incidentNumber"
            label="Incident Number"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="mpin"
            label="MPIN"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="networkID"
            label="Network ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="nicedecID"
            label="NICE DEC ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="orgName"
            label="Org Name"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
        <Layout.Stack>
          <TextInput
            model="orignalSourceSystemID"
            label="Original Source System ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="pricingSchedule"
            label="Pricing Schedule"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="primaryClaimID"
            label="Primary Claim ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="providerContractSourceID"
            label="Provider Contract Source ID"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <TextInput
            model="pulseCode"
            label="Pulse Code"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
          <SelectInputMulti
            model="salesfoceQueueName"
            label="Salesforce Queue Name"
            isSearchable
            width="300px"
            css={{
              'abyss-select-input-multi-label': {
                fontWeight: '600',
              },
            }}
            options={[
              { label: 'Cirrus_BH Provider', value: 'Cirrus_BH Provider' },
              { label: 'Cirrus_Contract EQ', value: 'Cirrus_Contract EQ' },
              { label: 'Cirrus_Demo Ops', value: 'Cirrus_Demo Ops' },
              {
                label: 'Cirrus_Demo Program Delivery',
                value: 'Cirrus_Demo Program Delivery',
              },
              {
                label: 'Cirrus_NonFac BH Provider',
                value: 'Cirrus_NonFac BH Provider',
              },
              {
                label: 'Cirrus_NonFac Contract EQ',
                value: 'Cirrus_NonFac Contract EQ',
              },
              {
                label: 'Cirrus_NonFac Contract Ops',
                value: 'Cirrus_NonFac Contract Ops',
              },
              {
                label: 'Cirrus_NonFac Demo Ops',
                value: 'Cirrus_NonFac Demo Ops',
              },
              {
                label: 'Cirrus_NonFac Pick Ops',
                value: 'Cirrus_NonFac Pick Ops',
              },
              {
                label: 'Cirrus_NonFac Pricing',
                value: 'Cirrus_NonFac Pricing',
              },
              {
                label: 'Cirrus_NonFac Risk Ops',
                value: 'Cirrus_NonFac Risk Ops',
              },
              {
                label: 'Cirrus_PCAP Program Delivery',
                value: 'Cirrus_PCAP Program Delivery',
              },
              { label: 'Cirrus_Pick Ops', value: 'Cirrus_Pick Ops' },
              { label: 'Cirrus_Pricing', value: 'Cirrus_Pricing' },
              { label: 'Cirrus_Provider_Pick', value: 'Cirrus_Provider_Pick' },
              { label: 'Cirrus_Risk Ops', value: 'Cirrus_Risk Ops' },
              { label: 'CT_CD Pricing Set', value: 'CT_CD Pricing Set' },
              { label: 'CT_CDML', value: 'CT_CDML' },
              { label: 'CT_Demo Ops', value: 'CT_Demo Ops' },
              {
                label: 'CT_NonFac CD Pricing Set',
                value: 'CT_NonFac CD Pricing Set',
              },
              { label: 'CT_NonFac CDML', value: 'CT_NonFac CDML' },
              {
                label: 'CT_NonFac Contract Ops',
                value: 'CT_NonFac Contract Ops',
              },
              { label: 'CT_NonFac Demo Ops', value: 'CT_NonFac Demo Ops' },
              { label: 'CT_NonFac PDO', value: 'CT_NonFac PDO' },
              { label: 'CT_PDO', value: 'CT_PDO' },
              {
                label: 'Demo Outbound_Demo Ops',
                value: 'Demo Outbound_Demo Ops',
              },
              {
                label: 'Demo Outbound_Demo Program Delivery',
                value: 'Demo Outbound_Demo Program Delivery',
              },
              {
                label: 'Demo Outbound_NonFac Demo Program Delivery',
                value: 'Demo Outbound_NonFac Demo Program Delivery',
              },
              {
                label: 'Demo Outbound_NonFac Demo Ops',
                value: 'Demo Outbound_NonFac Demo Ops',
              },
              {
                label: 'Demo Outbound Demo Program Delivery',
                value: 'Demo Outbound Demo Program Delivery',
              },
              { label: 'MDM_NonFac Demo Ops', value: 'MDM_NonFac Demo Ops' },
              {
                label: 'Misc_Error_Queue_Misc',
                value: 'Misc_Error_Queue_Misc',
              },
              { label: 'NDB_Demo Ops', value: 'NDB_Demo Ops' },
              { label: 'NDB_NonFac Demo Ops', value: 'NDB_NonFac Demo Ops' },
              { label: 'NDB Demo Ops', value: 'NDB Demo Ops' },
              { label: 'New Manual Errors', value: 'New Manual Errors' },
            ]}
          />
          <SelectInputMulti
            model="salesforceStatus"
            label="Salesforce Status"
            isSearchable
            width="300px"
            css={{
              'abyss-select-input-multi-label': {
                fontWeight: '600',
              },
            }}
            options={[
              { label: 'Auto Complete', value: 'Auto Complete' },
              {
                label: 'Auto Complete - Info Only',
                value: 'Auto Complete - Info Only',
              },
              {
                label: 'Auto Complete - Non Par', // **
                value: 'Auto Complete - Non Par',
              },
              {
                label: 'Auto Complete - Warning',
                value: 'Auto Complete - Warning',
              },
              {
                label: 'Closed - Excluded from Cirrus Contract',
                value: 'Closed - Excluded from Cirrus Contract',
              },
              {
                label: 'Closed - Excluded from Cirrus Demo',
                value: 'Closed - Excluded from Cirrus Demo',
              },
              {
                label: 'Closed - Inactive Not Loaded Records Without Impact',
                value: 'Closed - Inactive Not Loaded Records Without Impact',
              },
              {
                label: 'Closed - Invalid Details in Error',
                value: 'Closed - Invalid Details in Error',
              },
              {
                label: 'Closed - Termed Prior to Go Live',
                value: 'Closed - Termed Prior to Go Live',
              },
              {
                label: 'Closed - Voided or Collapsed Relationship',
                value: 'Closed - Voided or Collapsed Relationship',
              },
              {
                label: 'Complete - Demographics Complete in MDM',
                value: 'Complete - Demographics Complete in MDM',
              },
              {
                label: 'Complete - Direct Load to Cirrus Contract',
                value: 'Complete - Direct Load to Cirrus Contract',
              },
              {
                label: 'Complete - Direct Load to Cirrus Demo',
                value: 'Complete - Direct Load to Cirrus Demo',
              },
              {
                label: 'Complete - Manual Upload',
                value: 'Complete - Manual Upload',
              },
              { label: 'Complete - Non Par', value: 'Complete - Non Par' },
              { label: 'Completed', value: 'Completed' },
              {
                label: 'Completed - Manual Submission',
                value: 'Completed - Manual Submission',
              },
              {
                label: 'Completed - No System Update Needed',
                value: 'Completed - No System Update Needed',
              },
              { label: 'In Progress', value: 'In Progress' },
              { label: 'Open', value: 'Open' },
              { label: 'Pending - Completion', value: 'Pending Completion' },
              {
                label: 'Pending - Feed and Updates',
                value: 'Pending - Feed and Updates',
              },
              {
                label: 'Pending - System Enhancements',
                value: 'Pending - System Enhancements', // Pending System Enhancements
              },
              {
                label: 'Pending - System Updates',
                value: 'Pending - System Updates',
              },
              {
                label: 'Pending - Waiting on Other Teams',
                value: 'Pending - Waiting on Other Teams',
              },
              {
                label: 'Pending - Source Error Queue Cleanup',
                value: 'Pending Source Error Queue Cleanup',
              },
              {
                label: 'Pending Completion - Manual Upload',
                value: 'Pending Completion - Manual Upload',
              },
              {
                label: 'Pending Completion - Non Par',
                value: 'Pending Completion - Non Par',
              },
            ]}
          />
          <TextInput
            model="spLTK"
            label="SP LTK"
            width="300px"
            css={{
              'abyss-text-input-label': {
                fontWeight: '600',
              },
            }}
          />
        </Layout.Stack>
      </Layout.Group>
    </React.Fragment>
  );
};
