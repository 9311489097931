import React, { useEffect, useState } from 'react';
import { useForm } from '@abyss/web/hooks/useForm';
import { searchActivity } from './Activity.data';
import { CommonActivity } from './components/CommonActivity';
import { SMEReviewActivity } from './components/SMEReviewActivity';
import { AuditTaskActivity } from './components/AuditTaskActivity';

export const Activity = ({ errorId }) => {
  const [showActivities, setShowActivities] = useState(false);
  const [taskSubject, setTaskSubject] = useState('');
  const [activities, setActivities] = useState([]);

  const activityForm = useForm();

  useEffect(() => {
    searchActivity(
      errorId,
      activityForm,
      setShowActivities,
      setTaskSubject,
      setActivities
    );
  }, []);

  return (
    <React.Fragment>
      <div data-testid="activity-wrapper">
        {showActivities ? (
          activities?.map((activity) => {
            if (activity?.RECORDTYPENAME === 'SME Review') {
              return <SMEReviewActivity item={activity} />;
            }

            if (activity?.RECORDTYPENAME === 'Audit Task') {
              return <AuditTaskActivity item={activity} />;
            }

            return <CommonActivity item={activity} />;
          })
        ) : (
          <h3>No Activities associated with this Error</h3>
        )}
      </div>
    </React.Fragment>
  );
};
