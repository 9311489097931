import { Link } from '@abyss/web/ui/Link';
import { Button } from '@abyss/web/ui/Button';

export const attachmentDataTable = [
  {
    Header: 'Attachment Title',

    accessor: 'TITLE',
    disableResizing: true,
    width: 365,
  },

  {
    Header: 'Owner',

    accessor: 'OWNERNAME',
  },

  {
    Header: 'Download Link',

    accessor: 'DOWNLOAD_LINK',
    canToggleVisibilty: true,
    isHiddenByDefault: false,
    disableResizing: true,
    width: 100,
    Cell: ({ value }) => {
      // return (
      //   <Link href="https://www.google.com" openNewWindow>
      //     {value}{' '}
      //   </Link>
      // );
      return (
        <Button size="$sm" href={value} openNewWindow>
          Download{' '}
        </Button>
      );
    },
  },
  {
    Header: 'Record Type',

    accessor: 'RECORD_TYPE',
  },
  {
    Header: 'Record Number',

    accessor: 'RECORD_NUMBER',
  },
  // {
  //   Header: 'Download Link',
  //   accessor: 'DOWNLOADLINK',
  //   canToggleVisibilty: true,
  //   isHiddenByDefault: false,
  //   Cell: ({ value }) => {
  //     return (
  //       <Link href={${value}} openNewWindow>
  //         {' '}
  //         Download Link{' '}
  //       </Link>
  //     );
  //   },
  // },
];
