import React from 'react';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';

export const ActivityField = ({ item, field }) => {
  if (field?.id === 'errorNumber') {
    return (
      <TextInput
        key={`auditTask-${field?.id}`}
        model={field?.id}
        label={field?.label}
        isDisabled
        width="40%"
        css={{
          'abyss-text-input-label': {
            fontWeight: '600',
            color: '#323334',
          },
          'abyss-text-input-root': {
            marginRight: '50px',
          },
        }}
      />
    );
  }

  if (field.id.includes('Comment')) {
    return (
      <TextInputArea
        key={`auditTask-${field?.id}`}
        isDisabled
        label={field?.label}
        value={item?.[field?.column] ? item?.[field?.column] : ''}
        css={{
          'abyss-text-input-area-root': {
            width: '40%',
            fontWeight: '600',
            color: '#323334',
            marginRight: '50px',
          },
          'abyss-text-input-area-wrapper-container': {
            height: '40px',
            overflowY: 'scroll',
          },
          'abyss-text-input-area-label': {
            fontWeight: '600',
            color: '#323334',
          },
        }}
      />
    );
  }

  return (
    <TextInput
      key={`auditTask-${field?.id}`}
      // onChange={setErrorNumber}
      isDisabled
      label={field?.label}
      value={item?.[field?.column] ? item?.[field?.column] : ''}
      width="40%"
      css={{
        'abyss-text-input-label': {
          fontWeight: '600',
          color: '#323334',
        },
        'abyss-text-input-root': {
          marginRight: '50px',
        },
      }}
    />
  );
};
