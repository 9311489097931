import React from 'react';
import { Card } from '@abyss/web/ui/Card';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Tabs } from '@abyss/web/ui/Tabs';
import { Flex } from '@abyss/web/ui/Flex';
import { auditTaskActivityFields } from '@src/definations/AuditTaskActivityFields';
import { ActivityField } from './ActivityField';

export const AuditTaskActivity = ({ item }) => {
  return (
    <React.Fragment>
      <Card header="Audit Task" collapse defaultIsOpen={false}>
        <Card.Section>
          <Tabs>
            <Tabs.Tab label="Details">
              <Flex>
                {auditTaskActivityFields.map((fields) => {
                  return <ActivityField item={item} field={fields} />;
                })}
              </Flex>
            </Tabs.Tab>
          </Tabs>
        </Card.Section>
      </Card>
    </React.Fragment>
  );
};
