import React, { useMemo, useState, useEffect } from 'react';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { GeneralSearch } from '@src/components/GeneralSearch';
import { TextInput } from '@abyss/web/ui/TextInput';
import { commentsDataTable } from '@src/definations/comments';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import {
  searchAuditDetails,
  searchErrorDetails,
  searchComments,
  searchAttachmentDetails,
} from '@src/utils/use';

import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Activity } from '@src/components/Activity';
import { attachmentDataTable } from '@src/definations/attachment';
import { Header } from '@src/common/Header';

const RootContainer = styled(Layout.Stack, {
  padding: '6rem',
  minHeight: '20vh',
  justifyContent: 'space-between',
  variants: {
    medium: {
      true: {
        padding: '2rem',
      },
    },
  },
});

export const MoreDetails = ({ title }: { title: string }) => {
  const isMedium = useMediaQuery('(max-width: 744px)');

  const { getRouteParams } = useRouter();
  const params = getRouteParams();
  const [showAudit, setShowAudit] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [showComments, setShowComments] = useState(false);

  console.log('PARAM:::', params, params.errorid);

  // const [auditId, setAuditId] = useState('');
  // const [aerc, setAerc] = useState('');
  const detailForm = useForm();
  const auditForm = useForm();

  const dataTableComments = useDataTable({
    initialData: [],
    initialColumns: useMemo(() => {
      return commentsDataTable;
    }, []),
    showPagination: true,
    showTopPagination: true,
    showBottomPagination: true,
    showDownloadButton: true,
    paginationBottomCompact: true,
    pageSizeDefault: 5,
    pageSizeOptions: [5, 10, 20, 30],
  });

  const dataTableAttachment = useDataTable({
    initialData: [],
    initialColumns: useMemo(() => {
      return attachmentDataTable;
    }, []),
    // showPagination: true,
    showTopPagination: false,
    showBottomPagination: false,
    // showDownloadButton: true,
    // paginationBottomCompact: true,
    pageSizeDefault: 10,
    // pageSizeOptions: [5, 10, 20, 30],
  });

  useEffect(() => {
    searchErrorDetails(params.errorid, detailForm);
    // searchAuditDetails('5003u000018mvMPAAY', auditForm);  //for testing
    searchAuditDetails(params.errorid, auditForm, setShowAudit);
    searchComments(params.errorid, dataTableComments, setShowComments);
    searchAttachmentDetails(
      params.errorid,
      dataTableAttachment,
      setShowAttachment
    );
  }, []);

  return (
    <React.Fragment>
      <title>{title} | Search Archive Error</title>
      <meta name="description" content={`${title}Page`} />
      <Header title={title} />
      <RootContainer grow medium={isMedium}>
        <Heading offset={3}>Error Result</Heading>
        <FormProvider state={detailForm}>
          <Tabs title="Error Detail Tabs">
            <Tabs.Tab label="Details">
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                <TextInput
                  model="errorNumber"
                  key="errorNumber"
                  // onChange={setErrorNumber}
                  isDisabled
                  label="Error Number"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="cirrusTransactionId"
                  key="cirrusTransactionId"
                  // onChange={setCirrusTransactionId}
                  isDisabled
                  label="Cirrus Transaction ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="claimId"
                  key="claimId"
                  // onChange={setClaimId}
                  isDisabled
                  label="Claim ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="contractId"
                  key="contractId"
                  // onChange={setContractId}
                  isDisabled
                  label="Contract ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="corpProviderIdentifier"
                  key="corpProviderIdentifier"
                  // onChange={setCorpProviderIdentifier}
                  isDisabled
                  label="Corp Provider Identifier"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="correlationId"
                  key="correlationId"
                  // onChange={setCorrelationId}
                  isDisabled
                  label="Correlation ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="dateCreated"
                  key="dateCreated"
                  // onChange={setDateCreated}
                  isDisabled
                  label="Date Created"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="description"
                  key="description"
                  // onChange={setDescription}
                  isDisabled
                  label="Description"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="errorCode"
                  key="errorCode"
                  // onChange={setErrorCode}
                  isDisabled
                  label="Error Code"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="errorOwner"
                  key="errorOwner"
                  // onChange={setErrorOwner}
                  isDisabled
                  label="Error Owner"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="externalOwner"
                  key="externalOwner"
                  // onChange={setExternalOwner}
                  isDisabled
                  label="External Owner"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="externalStatus"
                  key="externalStatus"
                  // onChange={setExternalStatus}
                  isDisabled
                  label="External Status"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="hcoLTK"
                  key="hcoLTK"
                  // onChange={setHcoLTK}
                  isDisabled
                  label="HCO LTK"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="hcpLTK"
                  key="hcpLTK"
                  // onChange={setHcpLTK}
                  isDisabled
                  label="HCP LTK"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="hcppNum"
                  key="hcppNum"
                  // onChange={setHcppNum}
                  isDisabled
                  label="HCPP NUM"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="lastName"
                  key="lastName"
                  // onChange={setLastName}
                  isDisabled
                  label="Last Name"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="mPIN"
                  key="mPIN"
                  // onChange={setMPIN}
                  isDisabled
                  label="MPIN"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="networkID"
                  key="networkID"
                  // onChange={setNetworkID}
                  isDisabled
                  label="Network ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="niceDecID"
                  key="niceDecID"
                  // onChange={setNiceDecID}
                  isDisabled
                  label="NICE DEC ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="orgName"
                  key="orgName"
                  // onChange={setOrgName}
                  isDisabled
                  label="Org Name"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="ORG_TYPE_CD"
                  key="ORG_TYPE_CD"
                  // onChange={setOrgTypeCode}
                  isDisabled
                  label="ORG_TYPE_CD"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="pricingSchedule"
                  key="pricingSchedule"
                  // onChange={setPricingSchedule}
                  isDisabled
                  label="Pricing Schedule"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="PRICINGSETID"
                  key="PRICINGSETID"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="PRICING SET ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="providerContractSourceID"
                  key="providerContractSourceID"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="Provider Contract Source ID"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="providerRosterType"
                  key="providerRosterType"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="Provider Roster Type"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="reassign_HCO_SP_RelationshipKey"
                  key="reassign_HCO_SP_RelationshipKey"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="Reassign_HCO_SP_Relationship Key"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="reassign_HCP_LTK"
                  key="reassign_HCP_LTK"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="Reassign_HCP_LTK"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="salesforceQueueName"
                  key="salesforceQueueName"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="Salesforce Queue Name"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="SFStatus"
                  key="SFStatus"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="SF Status"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="SPLTK"
                  key="SPLTK"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="SP LTK"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="summary"
                  key="summary"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="Summary"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
                <TextInput
                  model="transactionRunDate"
                  key="transactionRunDate"
                  // onChange={setPricingSetID}
                  isDisabled
                  label="Transaction Run Date"
                  width="300px"
                  css={{
                    'abyss-text-input-label': {
                      fontWeight: '600',
                      color: '#323334',
                    },
                  }}
                />
              </div>
            </Tabs.Tab>
            <Tabs.Tab label="Audit">
              {showAudit ? (
                <FormProvider state={auditForm}>
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}
                  >
                    <TextInput
                      model="auditId"
                      key="auditId"
                      isDisabled
                      label="Audit ID"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInput
                      model="aerc"
                      key="aerc"
                      isDisabled
                      label="Audit Error Reason Code"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInput
                      model="auditStatus"
                      isDisabled
                      label="Audit Status"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInput
                      model="auditor"
                      isDisabled
                      label="Auditor"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInputArea
                      model="calibrationComments"
                      isDisabled
                      label="Calibration Comments"
                      width="300px"
                      css={{
                        'abyss-text-input-area-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                        'abyss-text-input-area-wrapper-container': {
                          overflowY: 'scroll',
                          width: '300px',
                          height: '40px',
                        },
                      }}
                    />
                    <TextInputArea
                      model="calibrationOutcome"
                      isDisabled
                      label="Calibration Outcome"
                      width="300px"
                      css={{
                        'abyss-text-input-area-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                        'abyss-text-input-area-wrapper-container': {
                          overflowY: 'scroll',
                          width: '300px',
                          height: '40px',
                        },
                      }}
                    />
                    <TextInputArea
                      model="correctionComments"
                      isDisabled
                      label="Correction Comments"
                      width="300px"
                      css={{
                        'abyss-text-input-area-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                        'abyss-text-input-area-wrapper-container': {
                          overflowY: 'scroll',
                          width: '300px',
                          height: '40px',
                        },
                      }}
                    />
                    <TextInput
                      model="dateCreated"
                      isDisabled
                      label="Date Created"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInput
                      model="dateCompleted"
                      isDisabled
                      label="Date Completed"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInputArea
                      model="finalComments"
                      isDisabled
                      label="Final Comments"
                      width="300px"
                      css={{
                        'abyss-text-input-area-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                        'abyss-text-input-area-wrapper-container': {
                          overflowY: 'scroll',
                          width: '300px',
                          height: '40px',
                        },
                      }}
                    />
                    <TextInputArea
                      model="finalOutcome"
                      isDisabled
                      label="Final Outcome"
                      width="300px"
                      css={{
                        'abyss-text-input-area-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                        'abyss-text-input-area-wrapper-container': {
                          overflowY: 'scroll',
                          width: '300px',
                          height: '40px',
                        },
                      }}
                    />
                    <TextInputArea
                      model="overturnedComments"
                      // onChange={setOverturnedComments}
                      isDisabled
                      label="Overturned Comments"
                      width="300px"
                      css={{
                        'abyss-text-input-area-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                        'abyss-text-input-area-wrapper-container': {
                          overflowY: 'scroll',
                          width: '300px',
                          height: '40px',
                        },
                      }}
                    />
                    <TextInput
                      model="overturnedReason"
                      isDisabled
                      label="Overturned Reason"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInput
                      model="owner"
                      isDisabled
                      label="Owner"
                      width="300px"
                      css={{
                        'abyss-text-input-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                      }}
                    />
                    <TextInputArea
                      model="qualityComments"
                      isDisabled
                      label="Quality Comments"
                      width="300px"
                      css={{
                        'abyss-text-input-area-label': {
                          fontWeight: '600',
                          color: '#323334',
                        },
                        'abyss-text-input-area-wrapper-container': {
                          overflowY: 'scroll',
                          width: '300px',
                          height: '40px',
                        },
                      }}
                    />
                  </div>
                </FormProvider>
              ) : (
                <h3>No Audit associated with this Error</h3>
              )}
            </Tabs.Tab>
            <Tabs.Tab label="Activity">
              <Activity errorId={params.errorid} />
            </Tabs.Tab>
            <Tabs.Tab label="Comments">
              {showComments ? (
                <DataTable title="Comments" tableState={dataTableComments} />
              ) : (
                <h3>No Comments associated with this Error</h3>
              )}
            </Tabs.Tab>

            <Tabs.Tab label="Attachment">
              {showAttachment ? (
                <DataTable
                  title="Attachment(s):"
                  tableState={dataTableAttachment}
                />
              ) : (
                <h3>No attachment associated with this error</h3>
              )}
            </Tabs.Tab>
          </Tabs>
        </FormProvider>
      </RootContainer>
    </React.Fragment>
  );
};
